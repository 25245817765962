import { useState, useEffect, useContext, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import type { Address } from './types';
import { fetchAddress } from './api/api';
import { EnrollmentContext } from './enrollment';
import { useTranslation } from 'react-i18next';

export const ValidateAddress = ({ handleSearchUtility, loadingUtility }: {
    handleSearchUtility: Function;
    loadingUtility: boolean;
}) => {
    const { t } = useTranslation('enrollment');
    const [ searching, setSearching ] = useState(false);
    const [ addressOptions, setAddressOptions ] = useState<Address[]>([]);
    const [ selectedAddress, setSelectedAddress ] = useState<Address[]>([]);
    const { changeServiceAddress, loading, serviceAddress } = useContext<any>(EnrollmentContext);
    const inputRef = useRef<any>();

    useEffect(() => {
        if (selectedAddress.length === 0) return;
        handleSearchUtility(selectedAddress[0]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    const handleSearch = (val:string) => {
        setSearching(true);
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setSearching(false);
        })
    }

    return (
        <>
            <div className="autocomplete-address-row">
                <div id="autocomplete-wrapper">
                    <AsyncTypeahead
                        ref={inputRef}
                        filterBy={() => true}
                        id="autocomplete"
                        isLoading={searching || loadingUtility}
                        labelKey="label"
                        minLength={1}
                        onSearch={handleSearch}
                        options={addressOptions}
                        placeholder={`${t('Address')}...`}
                        size="lg"
                        onChange={(val) => {
                            setSelectedAddress(val);
                            changeServiceAddress(val[0] ? val[0] : undefined);
                            if (val.length > 0) {
                                inputRef.current.blur();
                            }
                        }}
                        selected={selectedAddress.length > 0 ? selectedAddress : (serviceAddress ? [serviceAddress] : [])}
                        disabled={loading}
                    />
                </div>
            </div>
            {
                selectedAddress[0] &&
                <p style={{fontSize:12,marginTop:8,marginLeft:4,color:'#666'}}>{`ESIID: ${selectedAddress[0].esiid}`}</p>
            }
        </>
    )
}