import { PureComponent, useEffect, useState } from "react";
import { PlanProps } from "../types";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    ResponsiveContainer, ReferenceLine
} from 'recharts';
import { AsideBox, AsideBoxBody, AsideBoxHeader } from "./styles/Enrollment.styled";
import { useTranslation } from "react-i18next";
import { fetchUSNCompareDataNew } from "../api/api";

interface ChartDataTypes {
    id: string;
    value: number;
    label: string;
}

const OhComparisonDataStatic: any = {
    'OHIOED': {
        total_average_price: "100.6",
        name: 'Ohio Edison'
    },
    'TOLED': {
        total_average_price: "98",
        name: 'Toledo Edison'
    },
    'CEIL': {
        total_average_price: "96",
        name: 'Cleveland Illuminating'
    },
    'AEP-OHPC': {
        total_average_price: "109.1",
        name: 'AEP Ohio'
    },
    'DPL': {
        total_average_price: "91.8",
        name: 'AEP Ohio'
    },
    'AEP-CSP': {
        total_average_price: "109.1",
        name: 'Columbus Southern Power'
    },
    'DUKEOH': {
        total_average_price: "96",
        name: 'Duke'
    }
}

const utilityNameWrapper = (utility_name: string) => {
    let targetUtilities = ["NStar - BECO", "NStar - CAMB", "NStar - COM", "WMECO"];

    const cleanedUtilityName = utility_name.replace(/\s/g, '');
    const cleanedTargetUtilities = targetUtilities.map(utility => utility.replace(/\s/g, '').toLowerCase());
    const index = cleanedTargetUtilities.indexOf(cleanedUtilityName.toLowerCase());
    
    if (index !== -1) {
        return `Eversource (${targetUtilities[index].replace("NStar", "").replace("-", " ").trim()})`;
    } else {
        return utility_name;
    }
}

function customRound(number: number) {
    if (Number.isInteger(number)) {
        return number;
    } else {
        const integerPart = Math.floor(number);
        const decimalPart = number - integerPart;

        if (decimalPart < 0.5) {
            return integerPart;
        } else {
            return integerPart + 1;
        }
    }
}

function roundToOne(number: number) {
    try{
        return Number(Number(number).toFixed(1))
    }catch(err){
        return number
    }
}

export const RateComparisonUsnGas = ({
    product,
    utility,
    zipcode
}: {
    product: PlanProps[];
    utility: any;
    zipcode: any;
}) => {
    const { t } = useTranslation('enrollment');
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);
    const [ marketPrice, setMarketPrice ] = useState<any>(undefined);
    const [ canShow, setCanShow ] = useState(false);
    const [ plan, setPlan ] = useState<any>(undefined);
    const [ OhComparisonData, setOhComparisonData ] = useState<any>(OhComparisonDataStatic);

    useEffect(() => {
        if (!product) return;
        const gasPlan = product.find((val: any) => val.commodity_name.toLowerCase() === 'gas');
        setPlan(gasPlan);
    }, [product])

    useEffect(() => {
        let utilityCode;

        if (utility) {
            utilityCode = utility['Gas'];
        }

        if (Object.keys(OhComparisonData).indexOf(utilityCode) >= 0) {
            if(OhComparisonData[utilityCode]){
                setMarketPrice(OhComparisonData[utilityCode]);
            }
        }
        else {
            setMarketPrice(OhComparisonData['CEIL']);
        }
    }, [utility, OhComparisonData])

    useEffect(() => {
        if (!marketPrice) return;

        if (plan) {
            let rate = plan.rate;
            setChartData([{
                id: '0',
                value: rate,
                label: 'Clean Sky Energy'
            }, {
                id: '1',
                value: marketPrice.total_average_price,
                label: 'Average'
            }
            ])
        }
        else {
            setChartData([]);
        }
    }, [plan, marketPrice])

    useEffect(()=>{
        (async ()=>{
            if(!zipcode) return;
            try{
                const _data1 = await fetchUSNCompareDataNew(zipcode, utility["Gas"]);
                let _tempObj = {
                    [utility["Gas"]]: _data1?.response
                };
                setOhComparisonData(_tempObj);

                if(typeof Object.values(_tempObj)[0] === "object"){
                    setCanShow(true)
                }else{
                    setCanShow(false)
                }
            }catch(err){
                setOhComparisonData(OhComparisonDataStatic);
            }
        })()
    },[utility, zipcode])

    if(customRound((1 - chartData[0]?.value / chartData[1]?.value) * 100) < 5) return null;
    
    if (chartData.length === 0 || !canShow) return null;
    
    if (chartData[0].value > chartData[1].value) return null;

    const utilityName = (utility && utility.Gas) ? (OhComparisonData[utility.Gas]?.name ? OhComparisonData[utility.Gas]?.name : (OhComparisonData[utility.Gas+"G"]?.name ? OhComparisonData[utility.Gas+"G"]?.name : "")) : "";
    
    function getFirstDayOfCurrentMonth() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.toLocaleString('default', { month: 'long' });
      
        const firstDayOfMonth = new Date(year, today.getMonth(), 1);
        const formattedDate = `${month} 1, ${year}`;
      
        return formattedDate;
    }
    
    return (
        <AsideBox whiteBg className="mt-4">
            <AsideBoxHeader>
                <h4 className="mb-2" style={{fontSize:20,fontWeight:700}}>{t('Start Saving Today')}</h4>
                {
                    marketPrice &&
                    <h5 className="d-flex align-items-center">
                        {t('Total Savings')}: 
                        <span className="ms-2" style={{color:'#00b7f1'}}>{
                            customRound((1 - ((chartData[0].value) / (chartData[1].value))) * 100)
                        }%</span>
                    </h5>
                }
            </AsideBoxHeader>
            <AsideBoxBody>
            <div style={{height:130}}>
                <ResponsiveContainer>
                    <BarChart
                        data={chartData}
                        margin={{top: 30, bottom: 20}}

                    >
                        <XAxis dataKey="id" stroke="#bbb" tick={ <CustomizedTick utilityName={utilityName} t={t} /> } />
                        <YAxis
                            hide={true}
                            domain={['dataMin -1', 'dataMax']}
                        />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            maxBarSize={80}
                            shape={BarWithTopBorder()}
                            animationDuration={2000}
                            label={ <CustomizedLabel /> }
                        />
                        {
                            chartData[0] &&
                            <ReferenceLine
                                y={chartData[0].value}
                                stroke="#FFAF19"
                                strokeDasharray="5 3"
                                strokeWidth={1}
                            />
                        }
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <p style={{fontSize:12,color:'#999'}} className="text-center mb-0">{`Savings based on CleanSky Energy's current rate compared to utility price to compare as of ${getFirstDayOfCurrentMonth()}`}</p>
            </AsideBoxBody>
        </AsideBox>
    )
}


const BarWithTopBorder = () => {
	return (props: any) => {
		const { x, y, width, height, id } = props;

        let isHost = id === '0' ? true : false;
		return (
			<g className={`bar-cell ${isHost ? 'special' : ''}`}>
				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" />
				<rect x={x} y={y} width={width} height={3} stroke="none" className="bar-top" rx="2" ry="2" />
			</g>
		)
	}
}

class CustomizedTick extends PureComponent<{
    utilityName: string,
    t: any
}> {
    render() {
        const { x, y, payload, width, visibleTicksCount,t  }: Readonly<any> = this.props;
        const maxWidth = 125;
        const _width = width / visibleTicksCount > maxWidth ? maxWidth : width / visibleTicksCount;
        if (payload.value === 0 || payload.value === 'auto') return null;
        const { utilityName } = this.props;
        return (
            <g transform={`translate(${x},${y})`}>
                {
                    payload.value === '0' ? 
                    <image
                        x={_width / 2 * -1}
                        y={0}
                        xlinkHref={`/images/logo.svg`}
                        width={_width}
                    /> :
                    <foreignObject
                        x={-60}
                        y={0}
                        fill='#666'
                        fontSize={13}
                        width="120px"
                        height="40px"
                    >
                        <span style={{display:'inline-block',maxWidth:120,lineHeight:1,textAlign:'center',width:'100%'}}>{utilityNameWrapper(utilityName) || t('Market Average')}</span>
                    </foreignObject>
                }
            </g>
        )
    }
}

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, value, width }: Readonly<any> = this.props;
        return (
            <text
                x={x + width / 2}
                y={y}
                dy={-8}
                fill='#3a3a3a'
                fontSize={width / 5}
                fontWeight="700"
                textAnchor="middle"
            >
                {(value)}¢
            </text>
        )
    }
}
