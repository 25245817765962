import { useLocation, useNavigate } from 'react-router-dom'
import { PendingDepositTx } from './components/enrollment/ConfirmationResults/PendingDepositTx';
import { useEffect, useState } from 'react';
import { SuccessfulTx } from './components/enrollment/ConfirmationResults/SuccessfulTx';
import { PendingVerification } from './components/enrollment/ConfirmationResults/PendingVerification';
import { uiLogging, updateStatus } from './api/api';
import { shareAsaleNew, shareAsaleNew2 } from './utils/shareAsaleNew';
import { Trans, useTranslation } from "react-i18next";
import { Button } from './components/styles/Button.styled';
import { EnrollmentResultContent, PlanSummaryRow } from './components/styles/Enrollment.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgentPaymentScreen } from './components/enrollment/AgentWebPortal/AgentPaymentScreen';
import hubspotApiCalls from './utils/hubspotApiCalls';
import DepositOptions from './components/enrollment/ConfirmationResults/DepositOptions';
import DepositWaiver from './components/enrollment/ConfirmationResults/DepositWaiver';
import { SplitDepositTx } from './components/enrollment/ConfirmationResults/SplitDepositTx';
import ReceiptDownload from './components/enrollment/ConfirmationResults/ReceiptDownload';

const DepositSuccess = (enrollmentTokens: any, selectedPlans: any, selectedPlansML: any, depositConfig:any, split: string) =>{
    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
    const idSign = url.searchParams.get('sign');
    const idName = url.searchParams.get('name');
    const isAgent = url.searchParams.get('isAgent');
    const { t, i18n } = useTranslation('enrollment')
    const navigate = useNavigate();


    uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "Reached Thank you page")
    shareAsaleNew2(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "deposittx", "")
    // updateStatus(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "40.E04", "Deposit-Paid", null)
    
    let allDocs: any = {};
    if(selectedPlans?.length){
        selectedPlans?.forEach((plan: any) => {
            const { commodity_name, docs } = plan;
            if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
            const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
            allDocs[commodity_name] = availableDocs;
        })
    }else if(selectedPlansML?.length){
        selectedPlansML?.forEach((plan: any) => {
            const { commodity_name, docs } = plan;
            if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
            const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
            allDocs[commodity_name] = availableDocs;
        })
    }

    if(isAgent== "true"){
        return(
            <div className="text-center">
                    <h2 className="mb-3">{t('Thank you for your payment. Please continue and finish your enrollment with the Agent.')}</h2>
            </div>
        )
    }
    else return(
        <div>
            <div className="text-center">
                <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy')}</h1>
                <p>
                    {idName? 
                    <Trans i18nKey="enrollment:successfulPageDescDepositML1" components={{ 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                        </Trans>:
                    <Trans i18nKey="enrollment:successfulPageDesc" components={{7: <a href="mailto:verify@CleanSkyEnergy.com" />, 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                    </Trans>}
                </p>
                {
                    idName && <p>
                        <Trans i18nKey="enrollment:successfulPageDescDepositML2" components={{ 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                        </Trans>
                    </p>
                }
                {/* <p style={{fontSize:12}}>
                    {!idName && <Trans i18nKey="enrollment:successfulPageDesc2" components={{10: <a href="mailto:verify@CleanSkyEnergy.com" />}}>
                    </Trans>}
                </p> */}
                <br/>
                {split == "true" && 
                    <div>
                        <Button
                            onClick={()=>{navigate(`/receipt${window.location.search}`, {state: {
                                pagename: "Receipt",
                                depositConfig:depositConfig,
                                enrollmentTokens: enrollmentTokens,
                                case:"full"
                            }})}  
                        }>
                            {t('Download Receipt')}
                        </Button>
                    </div>
                }
                {split == "true" ?
                    <div 
                        className="mt-4"
                        style={{color:'#26B7EA',fontSize:17,cursor:"pointer",textDecoration: 'underline'}}
                        onClick={() => {
                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                        }}
                    > 
                        {t('Back to Homepage')}
                    </div>
                    :
                    <div className="mt-5">
                        <Button
                            withArrow
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            {t('Back to Homepage')}
                        </Button>
                    </div>
                }
                <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5 mb-5">
                    {
                        split == "true" ?
                        <p style={{fontSize:13}}>{t('View or print your documents here.')}</p> :
                        <p style={{fontSize:13}}>{t('Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site')}: <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">{t('Download')} Adobe Reader</a></p>
                    }
                    {
                        Object.keys(allDocs).map((type: string, typeIdx: number) => {
                            return (
                                <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                    <h6>{t(`Documents for ${type} Plan`)}</h6>
                                    <div className="plan-links mt-3">
                                        {
                                            allDocs[type].map((doc: any, idx: number) => (
                                                <PlanSummaryRow narrow key={idx} className="text-start">
                                                    <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                        <FontAwesomeIcon icon="file-pdf" />
                                                        <span className="ms-2">{doc.display_name}</span>
                                                    </a>
                                                </PlanSummaryRow>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <p style={{fontSize:12}}>{t("Benefits Beyond Energy Want to see about potential additional savings on recurring bills like cable, phone, internet, satellite (television and radio), pest control and home/alarm security? CleanSky Savings Assistant works to get you a better price for the exact same service and sometimes we are even able to get you an upgrade or add-on for the same or lower price! Here's the best part, it costs you nothing for the negotiations. We split the savings 50/50. Find out more at")} <a href="https://CleanSkyEnergy.com/savings-assistant/" target="_blank" rel="noreferrer">https://CleanSkyEnergy.com/savings-assistant/</a></p>
            </div>
        </div>
    )
}

const AutopaySuccess = (enrollmentTokens: any, selectedPlans: any, selectedPlansML: any) =>{

    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
    const idSign = url.searchParams.get('sign');
    const isAgent = url.searchParams.get('isAgent');

    const { t, i18n } = useTranslation('enrollment')
    uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "Reached Thank you page")
    shareAsaleNew2(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "autopaytx", "")
    // updateStatus(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "40.E04", "Autopay-Complete", null)

    let allDocs: any = {};
    if(selectedPlans?.length){
        selectedPlans?.forEach((plan: any) => {
            const { commodity_name, docs } = plan;
            if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
            const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
            allDocs[commodity_name] = availableDocs;
        })
    }else if(selectedPlansML?.length){
        selectedPlansML?.forEach((plan: any) => {
            const { commodity_name, docs } = plan;
            if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
            const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
            allDocs[commodity_name] = availableDocs;
        })
    }

    if(isAgent== "true"){
        return(
            <div className="text-center">
                    <h2 className="mb-3">{t('Thank you for setting up your autopay. Please continue and finish your enrollment with the Agent.')}</h2>
            </div>
        )
    }
    else return(
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy')}</h1>
                {idParam ? <p>
                    <Trans i18nKey="enrollment:successfulPageDescAutopayML1" components={{7: <a href="mailto:verify@CleanSkyEnergy.com" />, 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                    </Trans>
                </p>:
                <p>
                    <Trans i18nKey="enrollment:successfulPageDescAutopay" components={{7: <a href="mailto:verify@CleanSkyEnergy.com" />, 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                    </Trans>
                </p>}
                {
                    idParam && <p>
                        <Trans i18nKey="enrollment:successfulPageDescAutopayML2" components={{7: <a href="mailto:verify@CleanSkyEnergy.com" />, 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                    </Trans>
                </p>
                }
                {/* <p style={{fontSize:12}}>
                    <Trans i18nKey="enrollment:successfulPageDesc2" components={{10: <a href="mailto:verify@CleanSkyEnergy.com" />}}>
                        Please note that your enrollment details are subject to verification and that your service will not start until such time as your information can be validated.  Please send the following documents:  two forms of government issued ID (Driver's License (front & back), ID card (front & back), Passport, Military ID, Birth Certificate, Certificate of Citizenship N-560, N-561, N-645, Employment Authorization card (I-766), Social Security Card, Health Insurance Card, TennCare, Medicaid, Medicare, etc.) and the most recent copy of your lease or mortgage statement.  Please email these documents to <a href="mailto:verify@CleanSkyEnergy.com">verify@cleanskyenergy.com</a> using the same email used to enroll.
                    </Trans>
                </p> */}
                <div className="mt-5">
                    <Button
                        withArrow
                        onClick={() => {
                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                        }}>
                        {t('Back to Homepage')}
                    </Button>
                </div>
                <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5 mb-5">
                    <p style={{fontSize:13}}>{t('Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site')}: <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">{t('Download')} Adobe Reader</a></p>
                    {
                        Object.keys(allDocs).map((type: string, typeIdx: number) => {
                            return (
                                <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                    <h6>{t(`Documents for ${type} Plan`)}</h6>
                                    <div className="plan-links mt-3">
                                        {
                                            allDocs[type].map((doc: any, idx: number) => (
                                                <PlanSummaryRow narrow key={idx} className="text-start">
                                                    <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                        <FontAwesomeIcon icon="file-pdf" />
                                                        <span className="ms-2">{doc.display_name}</span>
                                                    </a>
                                                </PlanSummaryRow>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <p style={{fontSize:12}}>{t("Benefits Beyond Energy Want to see about potential additional savings on recurring bills like cable, phone, internet, satellite (television and radio), pest control and home/alarm security? CleanSky Savings Assistant works to get you a better price for the exact same service and sometimes we are even able to get you an upgrade or add-on for the same or lower price! Here's the best part, it costs you nothing for the negotiations. We split the savings 50/50. Find out more at")} <a href="https://CleanSkyEnergy.com/savings-assistant/" target="_blank" rel="noreferrer">https://CleanSkyEnergy.com/savings-assistant/</a></p>
            </div>
        </EnrollmentResultContent>
    )
}

const SplitDepositSuccess = (enrollmentTokens: any, selectedPlans: any, selectedPlansML: any,depositConfig:any) =>{

    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
    const idSign = url.searchParams.get('sign');
    const idName = url.searchParams.get('name');
    const isAgent = url.searchParams.get('isAgent');
    const { t, i18n } = useTranslation('enrollment');
    const navigate = useNavigate();


    uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "Reached Thank you page")
    shareAsaleNew2(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "deposittx", "")
    // updateStatus(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "40.E04", "Deposit-Paid", null)

    let allDocs: any = {};
    if(selectedPlans?.length){
        selectedPlans?.forEach((plan: any) => {
            const { commodity_name, docs } = plan;
            if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
            const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
            allDocs[commodity_name] = availableDocs;
        })
    }else if(selectedPlansML?.length){
        selectedPlansML?.forEach((plan: any) => {
            const { commodity_name, docs } = plan;
            if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
            const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
            allDocs[commodity_name] = availableDocs;
        })
    }

    if(isAgent== "true"){
        return(
            <div className="text-center">
                    <h2 className="mb-3">{t('Thank you for your payment. Please continue and finish your enrollment with the Agent.')}</h2>
            </div>
        )
    }
    else return(
        <div>
            <div className="text-center">
                <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy. Your partial deposit payment has been processed.')}</h1>
                <p>
                    <Trans i18nKey="enrollment:successfulPageDescDepositSplitSub1" components={{ 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}></Trans>
                </p>
                {
                    // idName && 
                    <p>
                        <Trans i18nKey="enrollment:successfulPageDescDepositML2" components={{ 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                        </Trans>
                    </p>
                }
                <br/>
                <div>
                    <Button
                        onClick={()=>{navigate(`/receipt${window.location.search}`, {state: {
                            pagename: "Receipt",
                            depositConfig:depositConfig,
                            enrollmentTokens: enrollmentTokens,
                            case:"split"
                        }})}  
                    }>
                        {t('Download Receipt')}
                    </Button>
                </div>
                <div 
                    className="mt-4"
                    style={{color:'#26B7EA',fontSize:17,cursor:"pointer",textDecoration: 'underline'}}
                    onClick={() => {
                        window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                    }}
                > 
                    {t('Back to Homepage')}
                </div>
                <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5 mb-5">
                    <p style={{fontSize:13}}>{t('View or print your documents here.')}</p>
                    {
                        Object.keys(allDocs).map((type: string, typeIdx: number) => {
                            return (
                                <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                    <h6>{t(`Documents for ${type} Plan`)}</h6>
                                    <div className="plan-links mt-3">
                                        {
                                            allDocs[type].map((doc: any, idx: number) => (
                                                <PlanSummaryRow narrow key={idx} className="text-start">
                                                    <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                        <FontAwesomeIcon icon="file-pdf" />
                                                        <span className="ms-2">{doc.display_name}</span>
                                                    </a>
                                                </PlanSummaryRow>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/* <p style={{fontSize:12}}>{t("Benefits Beyond Energy Want to see about potential additional savings on recurring bills like cable, phone, internet, satellite (television and radio), pest control and home/alarm security? CleanSky Savings Assistant works to get you a better price for the exact same service and sometimes we are even able to get you an upgrade or add-on for the same or lower price! Here's the best part, it costs you nothing for the negotiations. We split the savings 50/50. Find out more at")} <a href="https://CleanSkyEnergy.com/savings-assistant/" target="_blank" rel="noreferrer">https://CleanSkyEnergy.com/savings-assistant/</a></p> */}
            </div>
        </div>
    )
}

const ExtraPage: React.FC<{show: boolean, setScriptData: Function, setSpecialPro: Function, setStep:any}> = ({show, setScriptData, setSpecialPro, setStep}) => {
    const location = useLocation();

    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
    const idSign = url.searchParams.get('sign');
    const idName = url.searchParams.get('name');

    const getContent = () =>{
        // if(window.location.pathname!=="/deposit" && window.location.pathname!=="/depositwaiver" && window.location.pathname!=="/splitdeposit") return <DepositOptions additional={undefined} name={''} enrollmentTokens={undefined} selectedPlans={undefined}/>
        if(!location.state) return <div>Invalid Page</div>
        const data = location.state;
        switch (data?.pagename) {
            case "SuccessfulTx":
                return <SuccessfulTx
                    status={data?.status}
                    name={data?.name}
                    enrollmentTokens={data?.enrollmentTokens}
                    selectedPlans={data?.selectedPlans}
                    showFinalPage={data?.showFinalPage}
                /> 
            case "PendingDepositTx":
                return <PendingDepositTx
                    additional={data?.additional}
                    name={data?.name}
                    enrollmentTokens={data?.enrollmentTokens}
                    selectedPlans={data?.selectedPlans}
                    showFinalPage={data?.showFinalPage}
                    depositConfig={data?.depositConfig}
                    split={data?.split}
                />
            case "SplitDepositTx":
                return <SplitDepositTx
                    additional={data?.additional}
                    name={data?.name}
                    enrollmentTokens={data?.enrollmentTokens}
                    selectedPlans={data?.selectedPlans}
                    showFinalPage={data?.showFinalPage}
                    depositConfig={data?.depositConfig}
                />
            case "PendingVerification":
                return <PendingVerification status={data?.status} isAgent={false} next_step={data?.next_step}/>
            case "AgentSuccessfulTx":
                return <>
                    <AgentPaymentScreen purpose="autopay" enrollmentTokens={data?.enrollmentTokens} setScriptData={setScriptData} selectedPlans={data?.selectedPlans} setSpecialPro={setSpecialPro} setStep={setStep}/>
                </>
            case "AgentPendingDepositTx":
                return <>
                    <AgentPaymentScreen purpose="deposit" enrollmentTokens={data?.enrollmentTokens} setScriptData={setScriptData} selectedPlans={data?.selectedPlans} setSpecialPro={setSpecialPro} setStep={setStep}/>
                </>
            case "AgentPendingVerification":
                return <PendingVerification status={data?.status} isAgent={true} next_step={data?.next_step}/>
            case "DepositSuccess":
            // default:
                // hubspotApiCalls(data?.enrollmentTokens?.enrollment_id || idParam, data?.enrollmentTokens?.sign || idSign, "fraud_whitelisted_deposit_complete", window.location.href, "Thank you Page")
                return DepositSuccess(data?.enrollmentTokens, data?.selectedPlans, data?.selectedPlansML,data?.depositConfig, data?.split)
            case "Receipt":
                return <ReceiptDownload />
            case "AutopaySuccess":
                // hubspotApiCalls(data?.enrollmentTokens?.enrollment_id || idParam, data?.enrollmentTokens?.sign || idSign, "fraud_whitelisted_autopay_complete", window.location.href, "Thank you Page")
                return AutopaySuccess(data?.enrollmentTokens, data?.selectedPlans, data?.selectedPlansML)
            case "SplitDepositSuccess":
                return SplitDepositSuccess(data?.enrollmentTokens, data?.selectedPlans, data?.selectedPlansML,data?.depositConfig)
            case "Success":
                return null //TBD CASE
            case "DepositOptions":
                return <DepositOptions 
                    additional={data?.additional}
                    name={data?.name}
                    enrollmentTokens={data?.enrollmentTokens}
                    selectedPlans={data?.selectedPlans}
                    showFinalPage={data?.showFinalPage}
                /> //TBD CASE
            case "DepositWaiver":
                return <DepositWaiver />
            default:
                <div>Invalid Page</div>
        }
    }

    if(!show) return null
    return (
        <div>{getContent()}</div>
    )
}

export default ExtraPage
