import { useEffect, useState } from "react";
import { InputZipcode } from "./components/styles/Enrollment.styled";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function removeQueryParameters(paramsToRemove: string[]) {
    let url = window.location.href;
    let urlObj = new URL(url);
    let searchParams = urlObj.searchParams;

    paramsToRemove.forEach(param => {
        searchParams.delete(param);
    });

    let newUrl = `${urlObj.origin}${urlObj.pathname}`;

    if (searchParams.toString()) {
        newUrl += `?${searchParams.toString()}`;
    }

    window.history.replaceState({}, '', newUrl);
}

export const ValidateZipcode = ({ handleSearchUtility, loadingUtility, defaultZipcode } : {
    handleSearchUtility: Function;
    loadingUtility: boolean;
    defaultZipcode: string;
}) => {
    const { t } = useTranslation('enrollment');
    const [ zipcode, setZipcode ] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        if (defaultZipcode) {
            setZipcode(defaultZipcode);
        }

        const url = window.location.href;
        const urlObj = new URL(url);
        const zipcode = urlObj.searchParams.get('zipcode');
        setZipcode(zipcode || "")
        
    }, [])

    useEffect(() => {
        if (zipcode && zipcode.length === 5) {
            handleSearchUtility(zipcode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode])

    return (
        <div>
            <InputZipcode>
                <input
                    maxLength={5}
                    placeholder={`${t('Zip Code')}...`}
                    value={zipcode}
                    onChange={evt => {
                        const onlyNums = evt.target.value.replace(/[^0-9]/g, '');
                        setZipcode(onlyNums);
                    }}
                    disabled={loadingUtility}
                />
                {
                    loadingUtility &&
                    <Spinner animation="border" variant="secondary" size="sm" />
                }
            </InputZipcode>
        </div>
    )
}