import { useEffect, useState } from "react";
import { ValidateAddress } from "../../../validate_address";
import { dunsUtilityList } from '../../../config';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Button } from '../../styles/Button.styled'
import { getSwitchHoldDetails } from "../../../api/api";


interface Props {
    address: any;
    esiid: String;
    setSwitchStatus: Function;
  }

const SwitchHoldStatus: React.FC<Props> = ({address,esiid,setSwitchStatus}) =>{
    const defaultValue = [{ label: address, value: address}];
    const [selectedValue, setSelectedValue] = useState(defaultValue);  
    const [isActive, setIsActive] = useState(false);
    const [loading,setLoading] = useState(false)

    const loadOptions = () => {
        // Replace this with your async data fetching logic
        // For example, fetching data from an API
      return ({label:"no data", value:"No data"})
      };

      useEffect(()=>{
        (async()=>{
            setLoading(true)
            try{
                const res = await getSwitchHoldDetails(address?.esiid)
                if(res?.status == 200 && res?.switch_hold == "N"){
                    setSwitchStatus(false)
                    setIsActive(false)
                }else{
                    setSwitchStatus(true)
                    setIsActive(true)
                }
                setLoading(false)
            }catch(err){
                console.log('Data in err ==>', err)
                setLoading(false)
            }
        })();
    },[])
    
    return (
        loading?<></>:
        <div className='container mt-5 p-4 flex' style={{borderRadius: '11px', background: '#EFF7FA', height: isActive?'152px':"62px"}}>
            <div className='d-flex align-items-center mb-4'>
                <div className='me-3' style={{color: '#253F66', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>Switch Hold Status:</div>
                { isActive ? <div className='d-flex'>
                    <div className='me-1' style={{color: '#FF843F', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>Active</div>
                    <div style={{marginTop: '-5px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <rect width="18" height="18" fill="#EFF7FA"/>
                            <path d="M16.8254 13.9217L10.338 2.73684C9.77038 1.75439 8.27017 1.75439 7.70253 2.73684L1.17461 13.9217C0.64751 14.8664 1.37734 16 2.51263 16H15.4874C16.6227 16 17.3525 14.8664 16.8254 13.9217ZM8.83782 5.57085H9.20273C9.44601 5.57085 9.64874 5.75978 9.64874 5.98651L9.48655 10.1431C9.48655 10.3698 9.28382 10.5209 9.04055 10.5209C8.79727 10.5209 8.59454 10.332 8.59454 10.1431L8.43236 5.98651C8.39181 5.75978 8.59454 5.57085 8.83782 5.57085ZM9.5271 12.7503C9.36492 12.8637 9.20273 12.9393 9 12.9393C8.79727 12.9393 8.63509 12.8637 8.4729 12.7503C8.35126 12.637 8.27017 12.448 8.27017 12.2591C8.27017 12.0702 8.35126 11.919 8.4729 11.7679C8.63509 11.6545 8.79727 11.6167 9 11.6167C9.20273 11.6167 9.36492 11.6923 9.5271 11.8057C9.64874 11.919 9.72983 12.108 9.72983 12.2969C9.72983 12.448 9.64874 12.637 9.5271 12.7503Z" fill="#FF843F"/>
                        </svg>
                    </div>
                </div> :
                <div className='d-flex'>
                    <div className='me-1' style={{color: '#1AA128', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>None</div>
                </div>
                }
            </div>
            {isActive && 
            <>
                <p style={{color: '#253F66', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>Email Address for Documents</p>
                <div>
                    <input className='me-4' type='text' style={{borderRadius: '10px', textTransform: 'capitalize', border: '1px solid #CED4DA', width: '80%', height:'38px'}} placeholder={`enter email address`} />
                    <a type='submit'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <circle cx="16.5" cy="16.5" r="16.5" fill="#617692"/>
                            <path d="M7.47839 16.571C6.81763 16.9176 6.84504 17.4253 7.53778 17.6995L9.59032 18.5116C10.2836 18.7868 11.3327 18.6366 11.9204 18.178L20.8336 11.1489C21.4195 10.6881 21.4823 10.756 20.9735 11.3008L13.9273 18.8428C13.4167 19.3859 13.5675 20.053 14.2631 20.3219L14.5035 20.4156C15.1991 20.6846 16.3327 21.1363 17.0238 21.4173L19.3002 22.3442C19.9918 22.6252 20.7308 22.294 20.9272 21.5744L24.3967 8.8217C24.5925 8.10212 24.2122 7.79658 23.5514 8.14267L7.47839 16.571Z" fill="white"/>
                            <path d="M13.1111 24.9198C13.0705 25.0415 14.5148 22.8285 14.5148 22.8285C14.9232 22.2048 14.6913 21.4607 14.0014 21.1786L12.4263 20.5338C11.7364 20.2517 11.4058 20.5829 11.6913 21.2722C11.6913 21.2722 13.1528 24.7948 13.1111 24.9198Z" fill="white"/>
                        </svg>
                    </a>
                </div>
            </>}
        </div>
    )
}

export default SwitchHoldStatus;