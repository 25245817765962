import { useState } from "react";
import { Button } from '../../styles/Button.styled'
import { CheckboxDesc, CreditCheckSection, EnrollmentFormCol, EnrollmentFormRow, InputMaskToggle, InputWithMask } from "../../styles/Enrollment.styled";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { CheckboxItem } from "../../checkbox-item";
import { FormError } from "../FormError";
import { Form, Dropdown, Spinner, InputGroup, DropdownButton } from 'react-bootstrap'
import moment from "moment";
import { PlanSummary } from "../../PlanSummary";




const CreditCheckScreen: React.FC<{show:any, utility: any, utilityList: any}> = ({show, utility, utilityList}) =>{
    const { t, i18n } = useTranslation('enrollment');
    const [errorFields, setErrorFields] = useState<any>({});
    const [ showSSN, setShowSSN ] = useState(false);
    const [requiredFields, setRequiredFields] = useState<any>({
        ssn: { type: 'text' },
        date_of_birth: { type: 'date' },
    })
    const [selectedPlans,seSelectedPlan] = useState([])
    const [isTx,setIsTx] = useState(false)

    const [inputValue, setInputValue] = useState<any>({
            ssn: {
                type: "number",
                value: ""
            },
            date_of_birth: {
                type: "date"
            }
        }
    )

    const handleChangeInput = (evt: any) => {
        const { value, name } = evt.target

        let newInputValue = Object.assign({}, inputValue)
        newInputValue[name as any].value = value
        setInputValue(newInputValue)
    }


    const getDynamicFields = (type: string) => {
        const dynamicFields:any = {
            "credit_check": {
                "ssn": {
                    "type": "text",
                    "label": "SSN"
                },
                "date_of_birth": {
                    "type": "date",
                    "label": "Date of Birth"
                }
            }
        }
        const group = dynamicFields[type]

        const validate = () => {
            let isValid = true
            let newErrorFields: any = {}
            
            for (let i in requiredFields) {
                const { type } = requiredFields[i]
                if (type === 'date') {
                    if (
                        // (i === 'date_of_birth' && creditCheckType.value !== 'dob') ||
                        isTx === false) continue;
                    if (
                        !inputValue[i]?.value ||
                        !(
                            moment(inputValue[i]?.value, 'MM/DD/YYYY', true).isValid()
                        )
                    ) {
                        newErrorFields[i] = t('Invalid Date')
                        isValid = false
                    }
                    if (i === 'date_of_birth') {
                        const age = inputValue[i]?.value ? moment().diff(moment(inputValue[i].value, 'MM/DD/YYYY'), 'years'):"";
                        
                        if (Number(age) < 18) {
                            newErrorFields[i] = t('Date of Birth is under 18 years')
                            isValid = false
                        }
                    }
                } 
                else {
                    if (!inputValue[i].value) {
                        if (
                            // (i === 'ssn' && creditCheckType.value !== 'ssn') ||
                        (i === 'ssn' && isTx === false)) continue;
                        newErrorFields[i] = t('This field is required')
                        isValid = false
                    }
                }
            }
    
            
            setErrorFields(newErrorFields)
            return isValid
        }
    
        const handleSave = async () => {
            if ( !validate()) return;
    
    
            let saveFields: any = {};
            let trueFields: any = {};
    
    
            for (let i in inputValue) {
                if (i === 'email2') continue
    
                const item = inputValue[i]
                    if (item?.type === 'number') {
                        saveFields[i] = item?.value ? item.value?.replace(/ /g, '') : ''
                    } else if (item?.type === 'date') {
                        saveFields[i] = moment(item.value)?.format('MM/DD/YYYY')
                    } else if (item?.type === 'select') {
                        saveFields[i] = item.value ? item.value?.value : ''
                    } else {
                        saveFields[i] = item?.value
                    }
            }

            console.log("special",saveFields)
    
        }

        return (
           
                <EnrollmentFormRow>
                    {Object.keys(group).map((val, idx) => {
                        const item = group[val]
                        const { label, type, description } = item
                        if (type === 'date') {
                            // if (val === 'date_of_birth' && creditCheckType.value !== 'dob') return null;
                            return (
                                <EnrollmentFormCol key={idx}>
                                    <Form.Group className={errorFields[val] ? 'error' : ''}>
                                        <Form.Label>{t(label)} *</Form.Label>
                                        <NumberFormat
                                            format="##/##/####"
                                            placeholder="MM/DD/YYYY"
                                            mask="_"
                                            name={val}
                                            value={inputValue[val].value}
                                            onChange={handleChangeInput}
                                            className="form-control"
                                            // disabled={loading}
                                        />
                                    </Form.Group>
                                    <FormError message={errorFields[val]} />
                                </EnrollmentFormCol>
                            )
                        } else {
                            if (val === 'ssn') {
                                // if (creditCheckType.value !== 'ssn') return null;
                                return (
                                    <EnrollmentFormCol key={idx}>
                                        <InputWithMask>
                                            <Form.Group className={errorFields[val] ? 'error' : ''}>
                                                <Form.Label>{t('SSN')} *</Form.Label>
                                                <NumberFormat
                                                    format="### ## ####"
                                                    mask="_"
                                                    name={val}
                                                    value={inputValue[val].value}
                                                    onChange={handleChangeInput}
                                                    className="form-control"
                                                    // disabled={loading}
                                                />
                                                {
                                                    !showSSN &&
                                                    <NumberFormat
                                                        type="password"
                                                        maxLength={9}
                                                        name={val}
                                                        value={inputValue[val].value}
                                                        onChange={handleChangeInput}
                                                        className="form-control mask-input"
                                                        // disabled={loading}
                                                    />
                                                }
                                            </Form.Group>
                                            <InputMaskToggle type="button" active={showSSN} onClick={() => setShowSSN(!showSSN)} />
                                        </InputWithMask>
                                        <FormError message={errorFields[val]} />
                                    </EnrollmentFormCol>
                                )
                            }
                        }
                    })}
                    <div style={{display:"flex",justifyContent:"flex-end",width:"100%",padding:"0px 25px"}}>
                        <Button type="button" onClick={()=>handleSave()} >
                                Continue
                        </Button>
                    </div>
                </EnrollmentFormRow>
            
        )
    }

    if (!show) return null
    return(
        <>
            <PlanSummary
                data={selectedPlans}
                isTx={isTx}
                enrollmentStep={2}
                handleShowPlanModal={() => null}
                utility={utility}
                utilityList={utilityList}
            />
            <div style={{margin:"32px 74px",maxWidth:"65%"}}>
                <CreditCheckSection>
                    <h5>{t('Utility Credit Check')}</h5>
                    <p>
                        {t('A utility soft credit check is necessary to assess whether a refundable deposit will be required. It does not affect your credit score. Your information is securely transmitted over an encrypted connection to CleanSky, a Texas Retail Energy Provider, which requires personal identification information to review and process your enrollment. CleanSky does not store, use, or sell any personal information collected.')}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="flex-fill">
                            {getDynamicFields('credit_check')}
                        </div>
                    </div>
                </CreditCheckSection>
                
            </div>
        </>
    )
}

export default CreditCheckScreen;