import { getIsAgentValid } from "../api/api";
import { AGENT_WEB_ENROLLMENT } from "../config";

const isAdmin = async () =>{
    try{
        if(AGENT_WEB_ENROLLMENT){
            const url = new URL(window.location.href);
            const agentId = url.searchParams.get('agent_id');
            if(agentId){
                const resp = await getIsAgentValid({id:agentId})
                const res =  resp[0].status
                return res
            }
        }else{
            return false
        }
    }catch(err){
        return false
    }
}

export default isAdmin;